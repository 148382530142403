import { Gender, Title } from "../enums/enum";

export const API_URL = {
  BASE: process.env.REACT_APP_BASE_API_URL,
  GET_PROVIDER: "/Provider",
  APPLY: "/Transaction",
  SEND_QUOTE_EMAIL: "/Quote",
  STORAGE: "/Storage",
  GET_NATIONALITY: "/Common/Nationality",
  GET_INSTITUTION: "/Common/Institution",
  TRANS_NIB_VISA_MASTERCARD_CHECKOUT: "/Transaction/NIB/VisaMastercardCheckout",
  TRANS_WEBHOOK_FLYWIRE: "/Transaction/Flywire/WebhookEventHandler",
  TRANS_GET_ALLIANZ_CHECKOUT: "/Transaction/GetAllianzCheckout",
  TRANS_ALLIANZ_PAYMENT: "/Transaction/AllianzPayment",
  TRANS_NEPAL_PAYMENT: "/Transaction/Nepal/Checkout",
  TRANS_PAYPAL_RETURN: "/Transaction/Paypal/Return",
  COMMON_CONFIG: "/Common/Config",
  GET_LOCATION_ANNOUNCEMENT: "/Common/LocationAnnouncement",
  GET_NIB_CHECKOUT: "/Transaction/GetNIBCheckout",
  DOWNLOAD_INVOICE: "/Storage",
  BACK_TO_TRANSACTION: "/Transaction/BackToTransaction",
  EXPIRE_TRANSACTION: "/Transaction/ExpireTransaction",
  GET_AGENT: "/Common/Agent",
  DELETE_FILE: "/Storage/Delete",
};

export const PAGE_URL = {
  OSHC: process.env.REACT_APP_OSHC_URL,
  STUDENT_INSURANCE_AU: "/student-insurance-au",
  OSHC_AU_GET_A_QUOTE: "/oshc-au-get-a-quote",
  OSHC_AU_APPLY_A_QUOTE: "/oshc-au-apply-a-quote",
  OSHC_AU_PAYMENT_A_QUOTE: "/oshc-au-payment-a-quote",
  ALLIANZ_PAYMENT: "/allianz-payment/:id",
  NIB_PAYMENT: "/nib-payment/:id",
  PAYPAL_RETURN: "/paypal/return",
  PAYPAL_CANCEL: "/paypal/cancel",
  ALIPAY_RETURN: "/alipay/return",
  WECHAT_RETURN: "/wechat/return",
  BACK_TO_TRANSACTION: "/back-to-transaction",
};

export const DEFAULT_FORMAT_DATE = "DD/MM/YYYY";
export const API_FORMAT_DATE = "YYYY-MM-DD";
export const TIME_ZONE_SYDNEY_AUSTRALIA = "Australia/Sydney";
export const DATE_PICKER_FORMAT_DATE = "dd/MM/yyyy";

export const PROVIDER_ID = {
  AHM: "adca2ac6-2dfd-4c3f-bafc-3129327c8f13",
  MEDIBANK: "c6b99e23-c461-4a63-88cd-a13f8449edea",
  ALLIANZ: "7baa13b0-7a0c-40f1-8f68-22846491bddc",
  BUPA: "df4e0807-d9aa-4bd1-92c9-262219a2ab61",
  NIB: "c3615832-5427-4fcd-8d15-a4c51fd7fe03",
};

export const PROVIDER_LIST = [
  {
    providerId: "adca2ac6-2dfd-4c3f-bafc-3129327c8f13",
    providerName: "AHM",
    price: 0,
    logoUrl: "https://oshcstudents.com.au/wp-content/uploads/2016/01/ahm.png",
    oshcDocumentUrl:
        "https://oshcstudents.com.au/wp-content/uploads/2016/01/ahm_OSHC_Cover_Summary-2019.pdf",
    oshcCardUrl:
        "https://oshcstudents.com.au/wp-content/uploads/2016/01/AHM-membership-card.pdf",
    oshcGuidelineUrl:
        "https://oshcstudents.com.au/wp-content/uploads/2016/01/AHM-guideline-1.pdf",
    priceContainerId: "price_ahm",
    priceLoadingImgId: "price_waiting_ahm",
    priceTagId: "price_ahm_id",
    priceTagClass: "price_ahm",
    btnSubmitClass: "btn_ahm",
    btnSubmitId: "btn_ahm",
    btnQuoteNowId: "btn_quote_ahm",
    sort: 0,
    loading: false,
  },
  {
    providerId: "c6b99e23-c461-4a63-88cd-a13f8449edea",
    providerName: "Medibank",
    price: 0,
    logoUrl:
        "https://oshcstudents.com.au/wp-content/uploads/2016/01/medibank.png",
    oshcDocumentUrl:
        "https://oshcstudents.com.au/wp-content/uploads/2016/01/Essentials_OSHC_Cover_Summary-2019.pdf",
    oshcCardUrl:
        "https://oshcstudents.com.au/wp-content/uploads/2016/01/Medibank-member-card.pdf",
    oshcGuidelineUrl:
        "https://oshcstudents.com.au/wp-content/uploads/2016/01/Medibank-guideline-1.pdf",
    priceContainerId: "price_mdb",
    priceLoadingImgId: "price_waiting_mdb",
    priceTagId: "price_mdb_id",
    priceTagClass: "price_mdb",
    btnSubmitClass: "btn_mdb",
    btnSubmitId: "btn_mdb",
    btnQuoteNowId: "btn_quote_mdb",
    sort: 1,
    loading: false,
  },
  {
    providerId: "7baa13b0-7a0c-40f1-8f68-22846491bddc",
    providerName: "Allianz",
    price: 0,
    logoUrl:
        "https://oshcstudents.com.au/wp-content/uploads/2016/01/Allianz_Care_Positive_RGB.jpg",
    oshcDocumentUrl:
        "https://www.allianzcare.com.au/content/dam/onemarketing/azpau/allianzcare/docs/policy/imp2914-oshc-standard.pdf",
    oshcCardUrl:
        "https://www.allianzcare.com.au/en/faqs/new-members-portal.html",
    oshcGuidelineUrl: "https://www.allianzcare.com.au/en/faqs.html",
    priceContainerId: "price_alli",
    priceLoadingImgId: "price_waiting_alli",
    priceTagId: "price_alli_id",
    priceTagClass: "price_alli",
    btnSubmitClass: "btn_alli",
    btnSubmitId: "btn_alli",
    btnQuoteNowId: "btn_quote_alli",
    sort: 2,
    loading: false,
  },
  {
    providerId: "df4e0807-d9aa-4bd1-92c9-262219a2ab61",
    providerName: "Bupa",
    price: 0,
    logoUrl: "https://oshcstudents.com.au/wp-content/uploads/2016/01/bupa.png",
    oshcDocumentUrl:
        "https://oshcstudents.com.au/wp-content/uploads/2016/01/BUPA-OSHC-Policy.pdf",
    oshcCardUrl:
        "https://oshcstudents.com.au/wp-content/uploads/2016/01/Bupa-member-card.pdf",
    oshcGuidelineUrl:
        "https://oshcstudents.com.au/wp-content/uploads/2016/01/BUPA-guideline.pdf",
    priceContainerId: "price_bupa",
    priceLoadingImgId: "price_waiting_bupa",
    priceTagId: "price_bupa_id",
    priceTagClass: "price_bupa",
    btnSubmitClass: "btn_bupa",
    btnSubmitId: "btn_bupa",
    btnQuoteNowId: "btn_quote_bupa",
    sort: 3,
    loading: false,
  },
  {
    providerId: "c3615832-5427-4fcd-8d15-a4c51fd7fe03",
    providerName: "NIB",
    price: 0,
    logoUrl: "https://oshcstudents.com.au/wp-content/uploads/2016/01/nib.png",
    oshcDocumentUrl:
        "https://oshcstudents.com.au/wp-content/uploads/2016/01/NIB-OSHC-Policy.pdf",
    oshcCardUrl: "",
    oshcGuidelineUrl:
        "https://oshcstudents.com.au/wp-content/uploads/2016/01/NIB-guideline-1.pdf",
    priceContainerId: "price_nib",
    priceLoadingImgId: "price_waiting_nib",
    priceTagId: "price_nib_id",
    priceTagClass: "price_nib",
    btnSubmitClass: "btn_nib",
    btnSubmitId: "btn_nib",
    btnQuoteNowId: "btn_quote_nib",
    sort: 3,
    loading: false,
  },
];

export const DEFAULT_APPLY_DATA = {
  id: "",
  providerId: "",
  startDate: null,
  endDate: null,
  numberOfAdults: null,
  numberOfChildren: null,
  title: Title.Mr,
  firstName: "",
  lastName: "",
  gender: Gender.Male,
  birthday: {
    day: 1,
    month: 1,
    year: 2000,
  },
  passport: "",
  nationality: "",
  paymentCountry: "",
  hasAustralianAddress: true,
  institution: "",
  studentId: "",
  existingMember: false,
  policyNumber: "",
  streetAndress: "",
  city: "",
  state: "",
  postCode: "",
  phone: "",
  email: "",
  emailConfirmation: "",
  educationAgent: "",
  agentCode: "",
  readTermsPolicy: false,
  confirmedInformation: false,
  acceptPolicyForDependentVisa: false,
  partner: [],
  children: [],
  passportFiles: [],
  offerLetterOrCOEFiles: [],
  birthCertificateFiles: [],
  coiFiles: [],
  identificationOfMotherOrFatherFiles: [],
  emailReceiveInvoice: "",
  uploadRequires: [false, false, false, false, false],
  expanedIndexs: [false, false, false, false, false],
};

export const MONTH_LIST = [
  { value: 1, label: "January" },
  { value: 2, label: "February" },
  { value: 3, label: "March" },
  { value: 4, label: "April" },
  { value: 5, label: "May" },
  { value: 6, label: "June" },
  { value: 7, label: "July" },
  { value: 8, label: "August" },
  { value: 9, label: "September" },
  { value: 10, label: "October" },
  { value: 11, label: "November" },
  { value: 12, label: "December" },
];

export const TITLE_LIST = [
  { value: Title.Mr, label: "Mr" },
  { value: Title.Mrs, label: "Mrs" },
  { value: Title.Ms, label: "Ms" },
];

export const GENDER_LIST = [
  { value: Gender.Male, label: "Male" },
  { value: Gender.Female, label: "Female" },
];

export const ALLIANZ_GENDER_LIST = [
  { value: Gender.Male, label: "Male" },
  { value: Gender.Female, label: "Female" },
  { value: Gender.X, label: "X" },
];

export const VALIDATION = {
  messages: {
    required: "This field is required.",
    englishRequired: "Only input English name.",
    invalidChildrenAge: "The age of a child must be under 18.",
    emailReceiverNotAllowed: "Sorry, but our providers are unable to accept emails from '@yahoo' addresses. Please consider using other alternatives such as Gmail, Outlook, etc."
  },
};

export const NATIONALITY_CODE = {
  nepal: "NPL",
  australia: "AUS",
};

export const VALID_FILE_EXTENSION = [
  "pdf",
  "jpeg",
  "jpg",
  "png",
  "bmp",
  "webp",
  "tif",
  "tiff",
  "svg",
  "ico",
  "heif",
  "heic"
];
